/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

/* eslint-disable no-param-reassign */
import { types } from 'mobx-state-tree';
import { getPaceAITabData } from '../services/getConfig';

const aIProject = types.model('AIProject', {
  id: types.maybeNull(types.string),
  projectKey: types.maybeNull(types.string),
  title: types.maybeNull(types.string),
  description: types.maybeNull(types.string),
  IconURI: types.maybeNull(types.string),
  projectLink: types.maybeNull(types.string),
  overviewMetaData: types.maybeNull(types.string)
});

export default types
  .model('AIProjectList', {
    projectList: types.array(aIProject)
  })
  .views(self => ({
    getAIProjects() {
      return [...self.projectList];
    }
  }))
  .actions(self => ({
    async getAIProjectData() {
      const aIdata = await getPaceAITabData();
      this.setAIProjectData(aIdata);
    },
    setAIProjectData(data) {
      data.forEach(item => {
        self.projectList.push(item);
      });
    }
  }));
