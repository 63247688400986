/*
 * PEARSON PROPRIETARY AND CONFIDENTIAL INFORMATION SUBJECT TO NDA
 * Copyright © 2020 Pearson Education, Inc.
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains the property of
 * Pearson Education, Inc. The intellectual and technical concepts contained
 * herein are proprietary to Pearson Education, Inc. and may be covered by U.S.
 * and Foreign Patents, patent applications, and are protected by trade secret
 * or copyright law. Dissemination of this information, reproduction of this
 * material, and copying or distribution of this software is strictly forbidden
 * unless prior written permission is obtained from Pearson Education, Inc.
 */

export default {
  EPUB: 'generate_epub_output',
  PROMOTE: 'promote_live',
  INSTRUCTOR_RESOURCE: 'add_instructor_resource_url',
  CUSTOM_ROLE: 'create_custom_user',
  CYPRESS_AUTHORING: 'project_edit',
  PRINT_ON_DEMAND: 'demand_on_print',
  EDIT_TITLE_AUTHOR: 'edit_project_title_author',
  EDIT_PRODUCT_ID: 'grid_crud_access',
  ADD_REMOVE_USER: 'project_add_delete_users',
  CREATE_NEW_VERSION: 'create_new_version',
  LEARNOSITY: 'learnosity_access',
  ALFRESCO_SITE: 'alfresco_crud_access',
  TOGGLE_TCM: 'toggle_tcm',
  WHATS_NEW_CONTENT: 'whats_new_content',
  LOB_MANAGER: 'businessUnit_permission_grid',
  USER_ACCOUNTS_BU: 'user_accounts_bu',
  TOGGLE_AUTO_NUMBERING: 'toggle_auto_numbering',
  CREATE_NEW_PROJECT: 'create_new_project',
  UPDATE_ASSESSMENT: 'update_assessment_access',
  PUBLISH_TO_GPS: 'publish_to_GPS',
  PUBLISH_TO_PRIZM: 'publish_to_prizm',
  PUBLISH_TO_VIRTUAL_SCHOOLS: 'publish_to_virtual_schools',
  AI_L1_ADMIN_SUPPORT: 'ai_l1_admin_support',
  ENGLISH_LOB_ADMIN: 'english_lob_admin',
  PUBLISH_TO_LESSON_BUILDER: 'publish_to_lesson_builder'
};
